import axios from 'axios';
import { api } from './url';


export const proceedText = async (source_text: string) => {
  try {
    const response = await axios.post(
      api.proceedText,
      { source_text },
    );
    return response.data
  } catch (err) {
    return Promise.reject();
  }
};
