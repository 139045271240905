import './styles/global.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChatPage, MainPage } from './pages';
import { Layout } from './components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Layout isFooter={true}>
                <MainPage />
            </Layout>
        ),
    },
    {
        path: '/chat',
        element: (
            <Layout isFooter={false}>
                <ChatPage />
            </Layout>
        ),
    },
]);

const App = () => {
    const theme = useSelector((state: RootState) => state.app.theme);
    useEffect(() => {
        if (theme === 'light') {
            document.body.classList.remove('dark');
            document.body.classList.add('light');
            return;
        }
        document.body.classList.remove('light');
        document.body.classList.add('dark');
    }, [theme]);

    return (
        <>
            <ReactNotifications className={theme === 'light' ? "notificationLight" : 'notificationDark'} />
            <RouterProvider router={router} />
        </>
    );
};

export default App;
