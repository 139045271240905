import { Footer } from '../Footer';
import { Header } from '../Header';

interface ILayout {
    children: React.ReactNode;
    isFooter?: boolean;
}
export const Layout: React.FC<ILayout> = ({ children, isFooter }) => {
    return (
        <>
            <Header />
            {children}
            {isFooter && <Footer />}
        </>
    );
};
