import { Fragment, useCallback, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { SendIcon } from '../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import DOMPurify from 'dompurify';
import { Button } from '../Button';
import useWebSocket from 'react-use-websocket';
import {
    getZipFile,
    getZipVerbs,
    proceedText,
    sendNerSearch,
    sendNumSearch,
    sendParsing,
    sendStetments,
    sendSubj,
    sendTokens,
} from '../../api';
import { api } from '../../api/url';
import { Message } from '../Message';
import { Typing } from '../Typing';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const Chat: React.FC = () => {
    const [messageHistory, setMessageHistory] = useState<
        { data: string; isUser: boolean; time: string }[]
    >([]);
    const [markupArr, setMarkupArr] = useState(['']);
    const [subjInfo, setSubjInfo] = useState([]);
    const isShowChat = useSelector((state: RootState) => state.app.isShowChat);
    const tokens = useSelector((state: RootState) => state.app.tokens);
    const theme = useSelector((state: RootState) => state.app.theme);
    const wsUrl = window.location.host.includes('localhost')
        ? api.chatWebSocket
        : `wss://${window.location.host}${api.chatWebSocket}`;
    const { sendMessage, lastMessage } = useWebSocket(wsUrl);
    const getDate = () => {
        const date = new Date();
        date.setHours(date.getHours() % 24);
        date.setMinutes(date.getMinutes());
        return `${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
    };
    useEffect(() => {
        if (lastMessage !== null) {
            setMarkupArr([]);
            const lastMessageObj = JSON.parse(lastMessage.data);
            setMessageHistory(prev =>
                prev.concat({
                    data: lastMessageObj.answer,
                    isUser: false,
                    time: lastMessageObj.date,
                }),
            );
        }
    }, [lastMessage]);

    const [inputValue, setInputValue] = useState('');

    const sendText = async () => {
        try {
            const response = await proceedText(inputValue);
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSendMessage = useCallback((text: string) => {
        sendMessage(text);
        if (text !== null) {
            setMarkupArr([]);
            setMessageHistory(prev =>
                prev.concat({ data: text, isUser: true, time: getDate() }),
            );
        }
    }, []);

    const handleKeyPress = async (key: string) => {
        if (key === 'Enter') {
            try {
                const s = await handleClickSendMessage(inputValue);
                const response = await proceedText(inputValue);
                return response;
            } catch (error) {
                console.error(error);
            } finally {
                setInputValue('');
            }
        }
    };

    const markupToReact = () => {
        return (
            <Fragment>
                <Fragment>
                    {markupArr.map((markup, index) => (
                        <div
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(markup),
                            }}
                        />
                    ))}
                </Fragment>
            </Fragment>
        );
    };
    const subjCurrentInfo = () => (
        <Fragment>
            {subjInfo.map((item: any) => (
                <>
                    {item.subj_name && (
                        <p key={item.subj_name}>
                            {item.subj_name} - {item.subj_count}
                        </p>
                    )}
                </>
            ))}
        </Fragment>
    );

    const sendCurrentTokens = async () => {
        try {
            const response = await sendTokens(tokens).then(data => data);
            const value = response.sentences.map(
                (value: any) => value.marked_html,
            );
            setMarkupArr(value);
            setSubjInfo([]);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentParsing = async () => {
        try {
            const response = await sendParsing(tokens).then(data => data);
            const value = response.sentences.map(
                (value: any) => value.marked_html,
            );
            setMarkupArr(value);
            setSubjInfo([]);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentNumSearch = async () => {
        try {
            const response = await sendNumSearch(tokens).then(data => data);
            const value = response.sentences.map(
                (value: any) => value.marked_html,
            );
            setMarkupArr(value);
            setSubjInfo([]);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentNer = async () => {
        try {
            const response = await sendNerSearch(tokens).then(data => data);
            const value = response.sentences.map(
                (value: any) => value.marked_html,
            );
            setMarkupArr(value);
            setSubjInfo([]);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentSubj = async () => {
        try {
            const response = await sendSubj(tokens).then(data => data);
            setSubjInfo(response.sentences);
            setMarkupArr(['']);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentStetments = async () => {
        try {
            const response = await sendStetments(tokens).then(data => data);
            const value = response.sentences.map(
                (value: any) => value.marked_html,
            );
            setMarkupArr(value);
            setSubjInfo([]);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const downloadFile = (fileData: Blob) => {
        let url = window.URL.createObjectURL(fileData);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'archive.zip';
        a.click();
    };
    const getActualZipFile = async () => {
        try {
            const response = await getZipFile(tokens).then(data => data);
            if (response) {
                downloadFile(response);
            }
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const getCurrentZipVerbs = async () => {
        try {
            const response = await getZipVerbs(tokens).then(data => data);
            if (response) {
                downloadFile(response);
            }
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        console.log(messageHistory);
    }, [messageHistory]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.messagesContainer}>
                    {messageHistory.map(item => (
                        <Message
                            id={item.data}
                            text={item.data}
                            time={item?.time ? item.time : getDate()}
                            isSentByUser={item.isUser}
                        />
                    ))}

                    {messageHistory.length % 2 !== 0 && (
                        <div className={styles.skeletonContainer}>
                            <Skeleton
                                circle
                                style={{
                                    minWidth: 32,
                                    height: 32,
                                }}
                            />
                            <div className={styles.typingAnimation}>
                                <Skeleton count={3} />
                            </div>
                        </div>
                    )}
                    <>{subjInfo.length ? subjCurrentInfo() : markupToReact()}</>
                </div>
            </div>
            <div className={styles.inputBox}>
                <div className={styles.inputWrapper}>
                    <input
                        className={`${styles.input} ${theme}`}
                        value={inputValue}
                        placeholder="Start typing..."
                        onChange={e => setInputValue(e.target.value)}
                        onKeyUp={e => handleKeyPress(e.key)}
                    />
                    <button
                        className={styles.button}
                        onClick={() => {
                            sendText();
                            handleKeyPress('Enter');
                        }}
                    >
                        <SendIcon />
                    </button>
                </div>
                <div className={styles.btnModulesContainer}>
                    <Button onClick={sendCurrentTokens}>Module 3</Button>
                    <Button onClick={sendCurrentParsing}>Module 4</Button>
                    <Button onClick={sendCurrentSubj}>Module 5</Button>
                    <Button onClick={sendCurrentStetments}>Module 6</Button>
                    <Button onClick={sendCurrentNumSearch}>Module 7</Button>
                    <Button onClick={sendCurrentNer}>Module 8</Button>
                    <Button onClick={getCurrentZipVerbs}>Module 10</Button>
                    <Button onClick={getActualZipFile}>Module 11</Button>
                </div>
            </div>
        </>
    );
};
