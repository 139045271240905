import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import { RootState } from '../../redux/store';

interface ITextArea {
    onChange: (value: string) => void;
}

export const TextArea: React.FC<ITextArea> = ({ onChange }) => {
    const theme = useSelector((state: RootState) => state.app.theme);
    return (
        <div
            className={`${styles.textAreaContainer} ${theme}`}
            style={{
                border:
                    theme === 'dark'
                        ? '1px solid rgba(255, 255, 255, 0.40)'
                        : '1px solid rgba(0, 0, 0, 0.25)',
            }}
        >
            <textarea
                onChange={e => onChange(e.target.value)}
                className={`${styles.textArea} ${theme}`}
                maxLength={4000}
                placeholder="No more than 4000 symbols"
            />
        </div>
    );
};
