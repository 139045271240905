import axios from 'axios';
import { api } from './url';


export const sendTokens = async (tokens: string[][]) => {
  try {
    const response = await axios.post(
      api.sendTokens,
      { tokens },
    );
    return response.data
  } catch (err) {
    console.error(err);
  }
};
