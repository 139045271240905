export const basePath = process.env.REACT_APP_URL

export const api = {
  sendSourceText: `${basePath}/module/1_2/text`,
  sendSourceFile: `${basePath}/module/1_2/file`,
  sendTokens: `${basePath}/module/3/tokens`,
  sendParsing: `${basePath}/module/4/tokens`,
  sendStetments: `${basePath}/module/6/tokens`,
  sendNumSearch: `${basePath}/module/7/tokens`,
  sendNerSearch: `${basePath}/module/8/tokens`,
  sendSubj: `${basePath}/module/5/tokens`,
  sendVerbsSearch: `${basePath}/module/10/tokens`,
  getZipVerbs: `${basePath}/module/10/zip`,
  sendAdjSearch: `${basePath}/module/11/tokens`,
  getZipFile: `${basePath}/module/11/zip`,
  proceedText: `${basePath}/module/13/text`,
  proceedFile: `${basePath}/module/13/file`,
  chatWebSocket: `${basePath}/module/13/ws`,
}