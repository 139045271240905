import { CloseIcon, TextIcon } from '../../assets/icons';
import styles from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsShowOriginalText } from '../../redux/reducers/appReducer';
import { RootState } from '../../redux/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const OriginalText = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isShow = useSelector(
        (state: RootState) => state.app.isShowOriginalText,
    );

    const sourceText = useSelector((state: RootState) => state.app.sourceText);
    const theme = useSelector((state: RootState) => state.app.theme);
    const handleCloseOriginalText = () =>
        dispatch(changeIsShowOriginalText(false));
    const handleOpenOriginalText = () =>
        dispatch(changeIsShowOriginalText(true));

    useEffect(() => {
        if (sourceText === '') {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceText]);

    return (
        <>
            {isShow ? (
                <div className={styles.container}>
                    <div className={styles.innerBox}>
                        <div className={styles.infoBox}>
                            <div className={styles.description}>
                                <div className={styles.iconBox}>
                                    <TextIcon />
                                </div>
                                <div className={styles.textBox}>
                                    <span className={styles.fileName}>
                                        Text
                                    </span>
                                    <span className={styles.originalText}>
                                        Original text
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.closeIconBox}
                                onClick={handleCloseOriginalText}
                            >
                                <CloseIcon
                                    color={
                                        theme === 'light' ? 'black' : 'white'
                                    }
                                />
                            </div>
                        </div>
                        <p>{sourceText}</p>
                    </div>
                </div>
            ) : (
                <div
                    className={styles.clearIconBox}
                    onClick={handleOpenOriginalText}
                >
                    <TextIcon />
                </div>
            )}
        </>
    );
};
