import { useDispatch, useSelector } from 'react-redux';
import { FileUpload, Switch, TextArea } from '../../components';
import { ThemeContext, themes } from '../../contexts/ThemeContext';
import styles from './style.module.scss';
import {
    changeSourceText,
    changeTextAreaText,
    changeTheme,
    changeTokens,
} from '../../redux/reducers/appReducer';
import { RootState } from '../../redux/store';
import { Store } from 'react-notifications-component';
import { useEffect } from 'react';
import { proceedFile, sendSourceFile } from '../../api';
import { useNavigate } from 'react-router';
import { concatenateStrings } from '../../utils';

export const MainPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeCurrentTheme = () => dispatch(changeTheme());

    const changeText = (text: string) => dispatch(changeTextAreaText(text));
    const theme = useSelector((state: RootState) => state.app.theme);

    const sendProceedFile = async (file: File) => {
        try {
            const response = await proceedFile(file);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const getTextFromFile = async (file: File) => {
        try {
            const response = await sendSourceFile(file);
            const secondResponse = await sendProceedFile(file);
            console.log(secondResponse);
            const result = concatenateStrings(response.tokens);
            dispatch(changeTokens(response.tokens));
            dispatch(changeSourceText(result));
            if (response.tokens.length) {
                navigate('/chat');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        Store.addNotification({
            id: 'successfullyApplied',
            title: 'Successfully applied!',
            message: 'Enter text and attach a file for s2schat to help you.',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 5000,
            },
        });
    }, []);

    const onSend = (file: File) => {
        sendProceedFile(file);
        getTextFromFile(file);
    };

    return (
        <div className={`${styles.container} ${theme}`}>
            <ThemeContext.Consumer>
                {({ theme, setTheme }: any) => (
                    <div className={`${styles.themeSwitchContainer} ${theme}`}>
                        <span>Light</span>
                        <Switch
                            isActive={theme === 'dark'}
                            onChange={() => {
                                if (theme === themes.light) {
                                    changeCurrentTheme();
                                    setTheme(themes.dark);
                                }
                                if (theme === themes.dark) {
                                    changeCurrentTheme();
                                    setTheme(themes.light);
                                    document.body.classList.remove('dark');
                                    document.body.classList.add('light');
                                }
                            }}
                        />
                        <span>Dark</span>
                    </div>
                )}
            </ThemeContext.Consumer>
            <p className={`${styles.title} ${theme}`}>Upload Knowledge</p>
            <div className={styles.contentEnterBox}>
                <FileUpload onSend={onSend} />
                <TextArea onChange={changeText} />
            </div>
        </div>
    );
};
