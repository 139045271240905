import { createSlice } from '@reduxjs/toolkit'


export interface CounterState {
  isShowChat: boolean;
  isShowOriginalText: boolean,
  theme: null | 'light' | 'dark',
  sourceText: string;
  textAreaText: string;
  tokens: string[][];
}

const initialState: CounterState = {
  isShowChat: true,
  isShowOriginalText: true,
  theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  sourceText: '',
  textAreaText: '',
  tokens: [['']]
}

export const counterSlice = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    changeIsShowChat: (state, payload) => {
      state.isShowChat = payload.payload
    },
    changeIsShowOriginalText: (state, payload) => {
      state.isShowOriginalText = payload.payload
    },
    changeSourceText: (state, payload) => {
      state.sourceText = payload.payload
    },
    changeTextAreaText: (state, payload) => {
      state.textAreaText = payload.payload
    },
    changeTokens: (state, payload) => {
      state.tokens = payload.payload
    },
    changeTheme: (state) => {
      state.theme === 'dark' ? state.theme = 'light' : state.theme = 'dark'
    },
  },
})

export const { changeIsShowChat, changeIsShowOriginalText, changeTheme, changeSourceText, changeTextAreaText, changeTokens } = counterSlice.actions

export default counterSlice.reducer