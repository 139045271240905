import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './style.module.scss';
import { CloseIcon } from '../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface ICustomModal {
    isOpen: boolean;
    closeModal: () => void;
    children: ReactNode;
}

export const CustomModal: React.FC<ICustomModal> = ({
    isOpen,
    closeModal,
    children,
}) => {
    const theme = useSelector((state: RootState) => state.app.theme);
    const [customStyles, setCustomStyles] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'inline-flex',
            padding: '36px 40px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            borderRadius: '8px',
            border: '1px solid #DEE2E6',
            background: theme === 'light' ? '#FFF' : '#000',
            boxShadow:
                '0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
        },
        overlay: {
            background:
                theme === 'light'
                    ? 'rgba(0, 0, 0, 0.50)'
                    : 'rgba(43, 43, 43, 0.50)',
            backdropFilter: 'blur(2.25px)',
        },
    });

    useEffect(() => {
        setCustomStyles({
            content: {
                ...customStyles.content,
                background: theme === 'light' ? '#FFF' : '#000',
            },
            overlay: {
                ...customStyles.overlay,
                background:
                    theme === 'light'
                        ? 'rgba(0, 0, 0, 0.50)'
                        : 'rgba(43, 43, 43, 0.50)',
            },
        });
    }, [theme]);

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <div
                        className={styles.iconCloseBox}
                        style={{
                            background:
                                theme === 'light' ? '#e5e5e5' : '#272727',
                        }}
                        onClick={closeModal}
                    >
                        <CloseIcon
                            color={theme === 'light' ? '#000' : '#fff'}
                        />
                    </div>
                </div>
                {children}
            </div>
        </Modal>
    );
};
