import { useEffect } from 'react';
import { Chat, OriginalText } from '../../components';
import type { RootState } from '../../redux/store';
import { getScreenSize } from '../../utils/getScreenSize';
import styles from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
    changeIsShowOriginalText,
    changeIsShowChat,
} from '../../redux/reducers/appReducer';

export const ChatPage = () => {
    const dispatch = useDispatch();
    const isShowChat = useSelector((state: RootState) => state.app.isShowChat);

    useEffect(() => {
        const size = getScreenSize();
        if (size === 'xs') {
            dispatch(changeIsShowChat(true));
            dispatch(changeIsShowOriginalText(false));
            return;
        }
        dispatch(changeIsShowChat(true));
        dispatch(changeIsShowOriginalText(true));
    }, [dispatch]);

    return (
        <div className={styles.container}>
            <OriginalText />
            {isShowChat && (
                <div className={styles.chatContainer}>
                    <p className={styles.chatWord}>Chat</p>
                    <Chat />
                </div>
            )}
        </div>
    );
};
