export const getScreenSize = () => {
  const size = window.innerWidth;
  if (size <= 500) {
    return "xs";
  } else if (size <= 768) {
    return "sm";
  } else if (size <= 1024) {
    return "md";
  } else if (size >= 1024) {
    return "lg";
  } else if (size <= 1440) {
    return "xl";
  } else {
    return "Unknown";
  }
}