
export const SendIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="paper-airplane" clipPath="url(#clip0_95_13159)">
    <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M1.5918 2.71245L2.38095 7.25004H7.25013C7.66434 7.25004 8.00013 7.58582 8.00013 8.00004C8.00013 8.41425 7.66434 8.75004 7.25013 8.75004H2.38095L1.5918 13.2876L13.9295 8.00004L1.5918 2.71245ZM0.988869 8.00004L0.0637969 2.68087C-0.0109877 2.25086 0.128154 1.81135 0.436783 1.50272C0.824568 1.11494 1.40938 1.00231 1.91345 1.21834L15.3158 6.9622C15.7309 7.14013 16.0001 7.54835 16.0001 8.00004C16.0001 8.45172 15.7309 8.85995 15.3158 9.03788L1.91345 14.7817C1.40938 14.9978 0.824568 14.8851 0.436783 14.4974C0.128154 14.1887 -0.0109879 13.7492 0.0637969 13.3192L0.988869 8.00004Z" fill="#8E8E93" />
  </g>
  <defs>
    <clipPath id="clip0_95_13159">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</svg>