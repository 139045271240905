import { useLocation, useNavigate } from 'react-router';
import { Button } from '../../components';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { sendSourceText } from '../../api/sendSourceText';
import {
    changeSourceText,
    changeTokens,
} from '../../redux/reducers/appReducer';
import { concatenateStrings } from '../../utils';
import { proceedText } from '../../api';

export const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useSelector((state: RootState) => state.app.theme);
    const isChatPage = location.pathname === '/chat';

    const sendProceedText = async (text: string) => {
        try {
            const response = await proceedText(text);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const textAreaText = useSelector(
        (state: RootState) => state.app.textAreaText,
    );
    const sendCurrentText = async () => {
        try {
            if (textAreaText !== '') {
                const response = await sendSourceText(textAreaText).then(
                    value => value,
                );
                const secondResponse = await sendProceedText(textAreaText).then(
                    value => value,
                );
                console.log(secondResponse)
                const result = concatenateStrings(response.tokens);
                dispatch(changeSourceText(result));
                dispatch(changeTokens(response.tokens));

                if (!isChatPage) {
                    navigate('/chat');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = async () => {
        if (!isChatPage) return sendCurrentText();
    };
    return (
        <footer
            className={`${styles.footer} ${theme}`}
            style={{
                boxShadow:
                    theme === 'light'
                        ? '0px 1px 1px 0px rgba(0, 0, 0, 0.1) inset'
                        : '0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset',
            }}
        >
            <div>
                <Button onClick={handleClick}>Enter chat</Button>
            </div>
        </footer>
    );
};
