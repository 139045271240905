import axios from 'axios';
import { api } from './url';


export const getZipVerbs = async (tokens: string[][]) => {
  try {
    const response = await axios.post(
      api.getZipVerbs,
      { tokens },
      { responseType: 'blob' }
    );
    return response.data
  } catch (err) {
    console.error(err);
  }
};
