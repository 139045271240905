import { useNavigate, useLocation } from 'react-router';
import styles from './style.module.scss';
import { Button } from '../Button';
import { CustomModal } from '../Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { sendSourceText } from '../../api/sendSourceText';
import {
    changeSourceText,
    changeTokens,
} from '../../redux/reducers/appReducer';
import { concatenateStrings } from '../../utils';
import { proceedText } from '../../api';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useSelector((state: RootState) => state.app.theme);
    const isChatPage = location.pathname === '/chat';
    const textAreaText = useSelector(
        (state: RootState) => state.app.textAreaText,
    );

    const sendProceedText = async (text: string) => {
        try {
            const response = await proceedText(text);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const sendCurrentText = async () => {
        try {
            if (textAreaText !== '') {
                const response = await sendSourceText(textAreaText).then(
                    value => value,
                );
                const secondResponse = await sendProceedText(textAreaText).then(
                    value => value,
                );
                console.log(secondResponse);
                const result = concatenateStrings(response.tokens);
                dispatch(changeSourceText(result));
                dispatch(changeTokens(response.tokens));
                if (!isChatPage) {
                    navigate('/chat');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = async () => {
        if (!isChatPage) return sendCurrentText();
        setIsOpen(true);
    };

    const closeModal = () => setIsOpen(false);
    const goToMain = () => {
        setIsOpen(false);
        navigate('/');
    };
    const titleHandleClick = () =>
        location.pathname === '/chat' ? handleClick() : goToMain();

    return (
        <>
            <CustomModal isOpen={isOpen} closeModal={closeModal}>
                <div
                    className={
                        theme === 'light'
                            ? styles.modalBody
                            : styles.modalBodyDark
                    }
                >
                    <p className={`${styles.title} ${theme}`}>
                        After leaving the chat room, the correspondence will be
                        lost
                    </p>
                    <p className={styles.description}>
                        Do you really want to get out of the chat room?
                    </p>
                    <div className={styles.btnBox}>
                        <button className={styles.noBtn} onClick={closeModal}>
                            No
                        </button>
                        <button className={styles.yesBtn} onClick={goToMain}>
                            Yes
                        </button>
                    </div>
                </div>
            </CustomModal>
            <header
                className={
                    theme === 'light' ? styles.container : styles.containerDark
                }
            >
                <button className={styles.title} onClick={titleHandleClick}>
                    <span>S2S</span>-Chat
                </button>
                <Button onClick={handleClick}>
                    {isChatPage ? 'Back To Upload' : 'Enter chat'}
                </Button>
            </header>
        </>
    );
};
