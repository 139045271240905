import { useState } from 'react';
import { CloseIcon, FileIcon, UploadIcon } from '../../assets/icons';
import styles from './style.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IFileUpload {
    onSend: (source_file: File) => void;
}

export const FileUpload: React.FC<IFileUpload> = ({ onSend }) => {
    const [file, setFile] = useState<File>();
    const theme = useSelector((state: RootState) => state.app.theme);

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        onSend(selectedFile);
    };

    const handleDeleteFile = () => setFile(undefined);

    const handleDrop = (e: any) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        setFile(selectedFile);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    return (
        <div className={`${styles.fileUploadContainer} ${theme}`}>
            <div
                className={styles.container}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{
                    border: `1px dashed ${
                        theme === 'light'
                            ? 'rgba(0, 0, 0, 0.25)'
                            : 'rgba(255, 255, 255, 0.25)'
                    }`,
                }}
            >
                <div className={styles.uploadIconBox}>
                    <UploadIcon color={theme === 'dark' ? '#fff' : '#000'} />
                </div>
                <p className={`${styles.dndText} ${theme}`}>
                    Drag 'n' drop some <span>txt, md, docx</span> file here, or
                    click to select files
                </p>
                <p className={styles.fileSizeText}>
                    JPG, PNG or PDF, file size no more than 10MB
                </p>
                <input
                    type="file"
                    onChange={handleFileChange}
                    title="Upload"
                    style={{ display: 'none' }}
                    id="file-upload"
                />
                <div className={`${styles.uploadInput} ${theme}`}>
                    <label
                        className={`${styles.uploadInput} ${
                            theme === 'dark' ? 'light' : 'dark'
                        }`}
                        htmlFor="file-upload"
                    >
                        Upload
                    </label>
                </div>
            </div>
            {file && (
                <div className={styles.fileBox}>
                    <p
                        className={styles.fileAdded}
                        style={{
                            color: theme === 'light' ? '#000' : '#fff',
                        }}
                    >
                        File added
                    </p>
                    <div className={styles.file}>
                        <div className={styles.infoBox}>
                            <div className={styles.iconBox}>
                                <FileIcon />
                            </div>
                            <div>
                                <p className={styles.fileName}>{file?.name}</p>
                                <p
                                    className={styles.fileSize}
                                    style={{
                                        color:
                                            theme === 'light'
                                                ? 'rgba(0, 0, 0, 0.7)'
                                                : 'rgba(255, 255, 255, 0.70)',
                                    }}
                                >
                                    {(file?.size / (1024 * 1024)).toFixed(2)}MB
                                </p>
                            </div>
                        </div>
                        <div
                            className={styles.closeIconBox}
                            onClick={handleDeleteFile}
                        >
                            <CloseIcon
                                color={theme === 'light' ? '#000' : '#fff'}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
