import axios from 'axios';
import { api } from './url';


export const proceedFile = async (source_file: File) => {
  try {
    const response = await axios.post(
      api.proceedFile,
      { source_file },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data
  } catch (err) {
    console.error(err);
  }
};
