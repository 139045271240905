import classNames from 'classnames';
import styles from './style.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface ButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    active?: boolean;
    onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    disabled = false,
    active = false,
    onClick = () => {},
}) => {
    const theme = useSelector((state: RootState) => state.app.theme);

    const buttonClasses = classNames(styles.button, {
        [styles.buttonDark]: theme === 'dark',
        //[styles.disabled]: disabled,
        //[styles.active]: active,
    });
    return (
        <button className={buttonClasses} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
