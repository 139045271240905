export const FileIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="iconamoon:file-light">
    <g id="Group">
      <path id="Vector" d="M7 21C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7Z" fill="#5662F6" fillOpacity="0.23" stroke="#5662F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <g id="Vector_2">
        <path d="M13 3V9H19" fill="#5662F6" fillOpacity="0.23" />
        <path d="M13 3V9H19" stroke="#5662F6" strokeWidth="1.5" strokeLinejoin="round" />
      </g>
    </g>
  </g>
</svg>
