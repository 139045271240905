import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import { RootState } from '../../redux/store';

interface IMessage {
    id: string | number;
    text: string;
    isSentByUser: boolean;
    time: string;
}

export const Message: React.FC<IMessage> = ({
    text,
    time,
    isSentByUser,
    id,
}) => {
    const theme = useSelector((state: RootState) => state.app.theme);
    const botMessage = theme === 'light' ? styles.message : styles.messageDark;
    const userMessage =
        theme === 'light' ? styles.userMessage : styles.userMessageDark;

    return (
        <div
            className={styles.container}
            style={{
                justifyContent: isSentByUser ? 'flex-end' : 'flex-start',
            }}
        >
            {!isSentByUser && <div className={styles.senderBox}>S2S</div>}
            <div className={isSentByUser ? styles.userMessage : botMessage}>
                <div className={styles.textWrapper}>
                    {!isSentByUser && (
                        <span className={styles.senderName}>S2S-Chat</span>
                    )}
                    <span className={styles.messageText}>{text}</span>
                </div>
                {time ? (
                    <div className={styles.timeBox}>
                        <span className={styles.time}>{time}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
