import { useState } from 'react';
import styles from './style.module.scss';

interface ISwitch {
    onChange: () => void;
    isActive: boolean;
}

export const Switch: React.FC<ISwitch> = ({ onChange, isActive }) => {
    const [isToggled, setIsToggled] = useState(isActive);
    const onToggle = () => {
        onChange();
        setIsToggled(!isToggled);
    };
    return (
        <label className={styles.toggleSwitch}>
            <input type="checkbox" checked={isToggled} onChange={onToggle} />
            <span className={styles.switch} />
        </label>
    );
};
